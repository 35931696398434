import React from 'react';
import { colors } from '@apollo/space-kit/colors';
import classnames from 'classnames';
import { Atmosphere } from './Atmosphere';
import { Temperature } from './Temperature';
import { IconStar } from '@apollo/space-kit/icons/IconStar';
import { IconClose } from '@apollo/space-kit/icons/IconClose';
import { Rings } from './Rings';
import { Climate } from './Climate';
import { Moons } from './Moons';
import { Paths } from './Paths';
import { Planet as PlanetType } from './Homeworld';

interface Props {
  starPlanet: (p: PlanetType) => void;
  canClose?: boolean;
  isStarred?: boolean;
  planet: PlanetType;
  paths?: string[][];
}

export const Planet: React.FC<Props> = ({
  planet,
  starPlanet,
  canClose,
  isStarred,
  paths,
}) => {
  return (
    <div
      className="col-6 col-sm-4 col-lg-3"
      style={{ paddingLeft: 5, paddingRight: 5, marginTop: 10 }}
    >
      <div
        style={{
          borderRadius: 6,
          backgroundColor: colors.black.light,
          fontSize: 12,
          padding: 10,
        }}
      >
        {/* <div>Distance from beacon: {distanceFromBeacon}</div> */}
        {planet.name && (
          <div style={{ position: 'relative' }}>
            <code>{planet.name}</code>
            <div
              className={classnames('star', { starred: isStarred })}
              onClick={() => {
                starPlanet(planet);
              }}
              style={{
                cursor: 'pointer',
                position: 'absolute',
                top: canClose ? -6 : -8,
                right: canClose ? -4 : -6,
                padding: 6,
              }}
            >
              {canClose ? (
                <IconClose style={{ height: 8, opacity: 0.4 }} />
              ) : (
                <IconStar style={{ height: 12, opacity: 0.4 }} />
              )}
            </div>
          </div>
        )}
        {planet.blockbuster && (
          <div style={{ marginTop: 10 }}>
            <span
              style={{
                padding: '2px 6px',
                backgroundColor: colors.blue.darkest,
                fontWeight: 600,
                color: colors.yellow.base,
              }}
            >
              BLOCKBUSTER
            </span>
          </div>
        )}
        {planet.climate && (
          // @ts-ignore
          <Climate climate={planet.climate} />
        )}
        {planet.temperature && (
          // @ts-ignore
          <Temperature temperature={planet.temperature} />
        )}
        {planet.atmosphere && (
          // @ts-ignore
          <Atmosphere atmosphere={planet.atmosphere} />
        )}
        {planet.hasOwnProperty('rings') && <Rings rings={planet.rings!} />}
        {planet.inhabited && (
          <div style={{ marginTop: 10, fontSize: 9 }}>
            <code>Intelligent life forms found.</code>
          </div>
        )}
        {planet.moons && (
          // @ts-ignore
          <Moons moons={planet.moons} />
        )}
        {planet.dragons && (
          <div style={{ marginTop: 10, fontSize: 9 }}>
            <img
              style={{ width: '100%' }}
              alt="Charzard Gif"
              src="https://media3.giphy.com/media/QYvr2Xvp9Fuh2/giphy.gif?cid=790b7611553f6e16af7cdd7ea78a1e64a7fc8fe8816b06c5&rid=giphy.gif"
            />
          </div>
        )}
        {planet.star && planet.star.name && (
          <div
            style={{
              marginTop: 10,
              display: 'flex',
              fontSize: 10,
            }}
          >
            <div
              style={{
                color: 'rgba(255, 255, 255, 0.4)',
                flex: 0,
                marginRight: 5,
              }}
            >
              Star:
            </div>
            <code style={{ flex: 1 }}>{planet.star.name}</code>
            {planet.star.slingshot && (
              <div
                style={{
                  flex: 0,
                  marginRight: 5,
                }}
              >
                <div
                  style={{
                    backgroundColor: colors.yellow.base,
                    padding: '0 4px',
                    fontWeight: 600,
                    borderRadius: 2,
                    color: colors.black.base,
                  }}
                >
                  SLINGSHOT
                </div>
              </div>
            )}
            {planet.star.type && (
              <div
                style={{
                  flex: 0,
                }}
              >
                <div
                  style={{
                    backgroundColor: colors.grey.light,
                    padding: '0 4px',
                    fontWeight: 600,
                    borderRadius: 2,
                    color: colors.black.base,
                  }}
                >
                  {planet.star.type}
                </div>
              </div>
            )}
          </div>
        )}
        {paths && <Paths paths={paths} />}
      </div>
    </div>
  );
};
