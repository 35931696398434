import React from 'react';
import { colors } from '@apollo/space-kit/colors';

export type ClimateType =
  | 'TROPICAL'
  | 'DAMP'
  | 'TEMPERATE'
  | 'DRY'
  | 'ARID'
  | 'SHITTY';
export const Climate: React.FC<{ climate: ClimateType }> = ({ climate }) => (
  <div
    style={{
      marginTop: 10,
      color: 'rgba(255, 255, 255, 0.4)',
      fontSize: 10,
      display: 'flex'
    }}
  >
    <span style={{ flex: 1 }}>Climate</span>
    <span
      style={{
        flex: 0,
        backgroundColor: colors.grey.light,
        padding: '0 4px',
        fontWeight: 600,
        borderRadius: 2,
        color: colors.black.base
      }}
    >
      {climate}
    </span>
  </div>
);
