import React from 'react';
import { Column, NextButton } from '../../App';

const Info: React.FC = () => (
  <div className="row">
    <Column>
      <h1>Overview</h1>
      <p>
        <strong>Operation Homeworld</strong> is an interactive, GraphQL-themed
        puzzlehunt. Work alone or with friends to complete a series of
        brain-bending challenges in server building, riddle solving, and query
        writing.
      </p>
      <p>
        The experience is divided into phases, linked above. You'll need to make
        sufficient progress in each phase to be able to proceed to the next. If
        you successfully complete the final phase, you'll earn your spot on the
        leaderboard.
      </p>
      <p>
        As with many things in life, there are lots of right answers. Some are
        better than others though, and your overall rank on the leaderboard will
        be determined by how good your answer is.
      </p>
      <NextButton nextPage="briefing" />
    </Column>
  </div>
);

export { Info };
