import React from 'react';
import { ALIENS, ALIEN, Column, NextButton } from '../../App';

export const Sensitive: React.FC = () => {
  return (
    <div className="row">
      <Column>
        <h1>
          <code>Optional:</code> Collect Sensitive Intel
        </h1>
        <h5 style={{ marginTop: 40 }}>Known {ALIEN} preferences</h5>
        <p>
          Much like any other conscious species, the {ALIENS} don’t just have
          requirements, they also have preferences! These things are{' '}
          <strong>nice to have</strong>. When you submit a planet to settle,
          your final score will be determined by the number of preferences your
          planet satisfies (assuming the planet also meets all requirements):
        </p>
        <ul>
          <li>
            The {ALIENS} really like surfing, which sort of requires waves which
            require tides which requires <code>moons</code>. They'd prefer the
            strongest possible tide.
          </li>
          <li>
            The planet ideally orbits a <code>MAIN_SEQUENCE</code> star.
          </li>
          <li>
            The planet’s climate is ideally <code>DAMP</code>.
          </li>
          <li>
            The planet’s total temperature range is ideally less than{' '}
            <code>100</code> kelvin.
          </li>
          <li>
            The <code>argonRatio</code> of the planet's atmosphere is ideally at
            least <code>0.7</code>, and the <code>sulfuricAcidRatio</code>{' '}
            ideally does not exceed <code>0.01</code>.
          </li>
        </ul>
        <h5 style={{ marginTop: 40 }}>SECRET {ALIEN} preferences</h5>
        <p>
          The schema you can see in the <code>B.R.A.T</code>'s documentation in
          Studio is a <b>partial schema</b>. There are also other,{' '}
          <em>secret</em> fields of the <code>Star</code> and{' '}
          <code>Planet</code> types that you might be able to figure out from
          your beacon intel. If you can determine what these hidden fields are{' '}
          <em>and</em> which values the {ALIENS} want those fields to{' '}
          <em>have</em>, you might be able find{' '}
          <b>
            the single most wonderful planet for the {ALIENS} in the entire
            galaxy!
          </b>
        </p>
        <NextButton nextPage="mission">Sounds good, I've got it.</NextButton>
      </Column>
    </div>
  );
};
