import React, { useState } from 'react';
import { colors } from '@apollo/space-kit/colors';
import { IconClose } from '@apollo/space-kit/icons/IconClose';
import { PlanetWithPaths } from './Homeworld';

const AtmosphereTableRow: React.FC<{
  open: boolean;
  name: string;
  value: number;
  filterType: string;
  onChange: (filterType: string, value: number) => void;
}> = ({ open, name, value, filterType, onChange }) => (
  <tr>
    <td style={{ paddingRight: 10 }}>
      {name} Ratio{' '}
      {open ? (
        <select
          value={filterType}
          onChange={(e) => {
            onChange(e.target.value, value);
          }}
        >
          <option>min</option>
          <option>max</option>
        </select>
      ) : (
        filterType
      )}
      : <code>{value}</code>
    </td>
    <td>
      {open && (
        <input
          type="range"
          min="0"
          max="1"
          value={value}
          step=".01"
          onChange={(e) => {
            onChange(filterType, parseFloat(e.target.value));
          }}
        />
      )}
    </td>
  </tr>
);

export const filterPlanets = (filter: any, planets: PlanetWithPaths[]) =>
  planets.filter(({ planet }) => {
    if (
      planet.climate &&
      filter.climate.length > 0 &&
      !filter.climate.includes(planet.climate)
    )
      return false;

    if (
      planet.temperature &&
      planet.temperature.min &&
      planet.temperature.min < filter.temperature.min
    )
      return false;

    if (
      planet.temperature &&
      planet.temperature.max &&
      planet.temperature.max > filter.temperature.max
    )
      return false;

    if (planet.atmosphere) {
      if (planet.atmosphere.argonRatio) {
        const f = filter.atmosphere.argonRatio;
        const p = planet.atmosphere.argonRatio;
        if (f.filterType === 'max' && p > f.value) return false;
        if (f.filterType === 'min' && p < f.value) return false;
      }
      if (planet.atmosphere.carbonDioxideRatio) {
        const f = filter.atmosphere.carbonDioxideRatio;
        const p = planet.atmosphere.carbonDioxideRatio;
        if (f.filterType === 'max' && p > f.value) return false;
        if (f.filterType === 'min' && p < f.value) return false;
      }
      if (planet.atmosphere.nitrogenRatio) {
        const f = filter.atmosphere.nitrogenRatio;
        const p = planet.atmosphere.nitrogenRatio;
        if (f.filterType === 'max' && p > f.value) return false;
        if (f.filterType === 'min' && p < f.value) return false;
      }
      if (planet.atmosphere.oxygenRatio) {
        const f = filter.atmosphere.oxygenRatio;
        const p = planet.atmosphere.oxygenRatio;
        if (f.filterType === 'max' && p > f.value) return false;
        if (f.filterType === 'min' && p < f.value) return false;
      }
      if (planet.atmosphere.sulfuricAcidRatio) {
        const f = filter.atmosphere.sulfuricAcidRatio;
        const p = planet.atmosphere.sulfuricAcidRatio;
        if (f.filterType === 'max' && p > f.value) return false;
        if (f.filterType === 'min' && p < f.value) return false;
      }
    }

    return true;
  });

export const Filter: React.FC<{
  planets: PlanetWithPaths[];
  filter: any;
  setFilter: (str: string) => void;
}> = ({ planets, filter, setFilter }) => {
  const [filterOpen, setFilterOpen] = useState(false);

  const [climateState, setClimateState] = useState(filter.climate);
  const [minTempState, setMinTempState] = useState(filter.temperature.min);
  const [maxTempState, setMaxTempState] = useState(filter.temperature.max);
  const [argon, setArgon] = useState(filter.atmosphere.argonRatio);
  const [co2, setCO2] = useState(filter.atmosphere.carbonDioxideRatio);
  const [nitrogen, setNitrogen] = useState(filter.atmosphere.nitrogenRatio);
  const [oxygen, setOxygen] = useState(filter.atmosphere.oxygenRatio);
  const [h2so4, setH2SO4] = useState(filter.atmosphere.sulfuricAcidRatio);

  const knownClimates = Array.from(
    new Set(planets.map((p) => p.planet.climate))
  ).filter((c) => c !== 'SHITTY');

  const filteredPlanets = filterPlanets(filter, planets);

  return (
    <div
      style={{
        padding: 10,
        paddingLeft: 20,
        backgroundColor: colors.indigo.dark,
        fontFamily: 'Menlo',
        fontSize: 12,
      }}
    >
      <div style={{ marginBottom: 20, display: 'flex' }}>
        {!!planets[0].planet.atmosphere ||
        !!planets[0].planet.climate ||
        !!planets[0].planet.temperature ? (
          <>
            <div style={{ flex: 1 }}>Filter processor results...</div>
            <div
              style={{
                flex: 'none',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              {filterOpen ? (
                <span
                  onClick={() => {
                    const newFilter = { ...filter };
                    newFilter.climate = climateState;
                    newFilter.temperature.min = minTempState;
                    newFilter.temperature.max = maxTempState;
                    newFilter.atmosphere.argonRatio = argon;
                    newFilter.atmosphere.carbonDioxideRatio = co2;
                    newFilter.atmosphere.nitrogenRatio = nitrogen;
                    newFilter.atmosphere.oxygenRatio = oxygen;
                    newFilter.atmosphere.sulfuricAcidRatio = h2so4;
                    setFilter(JSON.stringify(newFilter));
                    setFilterOpen(false);
                  }}
                >
                  Save
                </span>
              ) : (
                <span
                  onClick={() => {
                    setFilterOpen(true);
                  }}
                >
                  Edit
                </span>
              )}
            </div>
          </>
        ) : (
          <div>
            Upload a file with more raw information about planets to use the
            processor's filtering capabilities.
          </div>
        )}
      </div>
      <table style={{ flex: 1 }}>
        <tbody>
          {!!planets[0].planet.temperature && (
            <tr>
              <td style={{ width: '1% ' }}>
                <strong style={{ marginRight: 20 }}>Tempurature:</strong>
              </td>
              <td>
                <span style={{ marginRight: 5 }}>min</span>
                {filterOpen ? (
                  <input
                    type="number"
                    name="minTemp"
                    style={{ width: 60, marginRight: 20, height: 18 }}
                    value={minTempState}
                    onChange={(e) => {
                      setMinTempState(e.target.value);
                    }}
                  />
                ) : (
                  <code style={{ marginRight: 20 }}>{minTempState}</code>
                )}
                <span style={{ marginRight: 5 }}>max</span>
                {filterOpen ? (
                  <input
                    type="number"
                    name="maxTemp"
                    style={{ width: 60, height: 18 }}
                    value={maxTempState}
                    onChange={(e) => {
                      setMaxTempState(e.target.value);
                    }}
                  />
                ) : (
                  <code>{maxTempState}</code>
                )}
              </td>
            </tr>
          )}
          {!!planets[0].planet.climate && (
            <tr>
              <td style={{ width: '1% ' }}>
                <strong>Climate:</strong>
              </td>
              <td>
                {filterOpen ? (
                  <>
                    {climateState.map((c: string, i: number) => (
                      <span
                        key={i}
                        style={{ marginRight: 10, cursor: 'pointer' }}
                      >
                        <select
                          id="climate"
                          value={c}
                          onChange={(e) => {
                            const newState = climateState.slice();
                            newState[i] = e.target.value;
                            setClimateState(newState);
                          }}
                        >
                          {knownClimates.map((c) => (
                            <option key={c} value={c}>
                              {c}
                            </option>
                          ))}
                        </select>
                        <IconClose
                          style={{ height: 6, marginLeft: 4 }}
                          onClick={() => {
                            const newState = climateState.slice();
                            newState.splice(i, 1);
                            setClimateState(newState);
                          }}
                        />
                      </span>
                    ))}
                    <span
                      style={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                      }}
                      onClick={() => {
                        setClimateState([...climateState, knownClimates[0]]);
                      }}
                    >
                      add
                    </span>
                  </>
                ) : filter.climate.length > 0 ? (
                  <code>{filter.climate.join(', ')}</code>
                ) : (
                  <code>none</code>
                )}
              </td>
            </tr>
          )}
          {!!planets[0].planet.atmosphere && (
            <tr>
              <td style={{ width: '1%', verticalAlign: 'top' }}>
                <strong>Atmosphere:</strong>
              </td>
              <td>
                <table>
                  <tbody>
                    {!!planets[0].planet.atmosphere.argonRatio && (
                      <AtmosphereTableRow
                        open={filterOpen}
                        name="Argon"
                        value={argon.value}
                        filterType={argon.filterType}
                        onChange={(filterType, value) => {
                          setArgon({ filterType, value });
                        }}
                      />
                    )}
                    {!!planets[0].planet.atmosphere.carbonDioxideRatio && (
                      <AtmosphereTableRow
                        open={filterOpen}
                        name="Carbon Dioxide"
                        value={co2.value}
                        filterType={co2.filterType}
                        onChange={(filterType, value) => {
                          setCO2({ filterType, value });
                        }}
                      />
                    )}
                    {!!planets[0].planet.atmosphere.nitrogenRatio && (
                      <AtmosphereTableRow
                        open={filterOpen}
                        name="Nitrogen"
                        value={nitrogen.value}
                        filterType={nitrogen.filterType}
                        onChange={(filterType, value) => {
                          setNitrogen({ filterType, value });
                        }}
                      />
                    )}
                    {!!planets[0].planet.atmosphere.oxygenRatio && (
                      <AtmosphereTableRow
                        open={filterOpen}
                        name="Oxygen"
                        value={oxygen.value}
                        filterType={oxygen.filterType}
                        onChange={(filterType, value) => {
                          setOxygen({ filterType, value });
                        }}
                      />
                    )}
                    {!!planets[0].planet.atmosphere.sulfuricAcidRatio && (
                      <AtmosphereTableRow
                        open={filterOpen}
                        name="Sulfuric Acid"
                        value={h2so4.value}
                        filterType={h2so4.filterType}
                        onChange={(filterType, value) => {
                          setH2SO4({ filterType, value });
                        }}
                      />
                    )}
                  </tbody>
                </table>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div style={{ marginTop: 20 }}>
        {filteredPlanets.length === 0 ? (
          <span>
            <code>No planets</code> matched your filter! Try loosening your
            requirements a bit.
          </span>
        ) : (
          <span>
            Showing{' '}
            <code>
              {filteredPlanets.length} of {planets.length}
            </code>{' '}
            planets from the received data.
          </span>
        )}
      </div>
    </div>
  );
};
