import React from 'react';

interface Props {
  rings: number;
}
export const Rings: React.FC<Props> = ({ rings }) => {
  const numerate: any = [];
  for (let i = 0; i < rings; i++) {
    numerate.push(i);
  }
  return (
    <div style={{ marginTop: 10, display: 'flex', alignItems: 'center' }}>
      <div
        style={{
          flex: 1,
          color: 'rgba(255, 255, 255, 0.4)',
          fontSize: 10
        }}
      >
        {rings === 0 && 'No'} Rings
      </div>
      <div style={{ lineHeight: 1, justifyContent: 'flex-end' }}>
        {numerate.map((i: number) => (
          <div
            key={i}
            style={{
              height: 8,
              width: 8,
              borderRadius: 8,
              display: 'inline-block',
              border: '1px solid rgba(255, 255, 255, 0.4)',
              marginRight: 4
            }}
          />
        ))}
      </div>
    </div>
  );
};
