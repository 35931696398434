import React from 'react';
import { ALIENS, ALIEN, Column, NextButton } from '../../App';
import { colors } from '@apollo/space-kit/colors';

const Control: React.FC = () => (
  <div className="row">
    <Column>
      <h1>Welcome to Mission Control</h1>
      <p>It's good to have you on-board.</p>
      <p>
        To scout out the closest neighboring galaxy, the {ALIENS} have deployed
        a <b>deep-space beacon</b> 🛰. The beacon transmits information about
        nearby stars and planets to the {ALIEN} homeworld via a subspace
        frequency. It also offloads complex calculations to the homeworld to
        preserve its own energy.
      </p>
      <p>
        Your first job is to build a <strong>receiver</strong> 📡 for the
        beacon's data. Once you establish yourself as trustworthy, the beacon
        will start sending you <strong>intel</strong> about the stars and
        planets it's finding in the field.
      </p>
      <p>
        The intel you receive will be all the information you need to start
        exploring the universe in your search for a new home-world for the Bergals.
        Once you've decoded the intel received, and determine what word each
        is meant to communicate, you can control the beacon remotely to explore
        the universe!
      </p>
      <p>
        The {ALIEN} beacon has a truly unique power: it can instantly travel to{' '}
        <b>any star it knows the name of</b>. You provide it with a name via the{' '}
        <strong>Beacon Remote Access Terminal</strong>, or <code>B.R.A.T.</code>
        , and the beacon will instantly travel there and send home{' '}
        <strong>data</strong> about the planets and stars nearby its new spot.
      </p>
      <p>
        The {ALIENS} think that through smooth maneuvering of the{' '}
        <strong>beacon</strong>, you will be able to find a habitable new planet
        for them to settle and map a way through the galaxy for their enormous
        colony ship to get there.
      </p>
      <p>
        You will need to{' '}
        <a
          href="https://studio.apollographql.com/org/bergals/invite/42c60639-fbbd-4dbc-bb55-2ebc4c11d318"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'underline', color: colors.blue.light }}
        >
          register with Mission Control
        </a>{' '}
        to have a secure way to connect to the <code>B.R.A.T.</code>.
      </p>
      <p>Good luck, star spotter... all hopes lay with you now.</p>
      <NextButton nextPage="beacon">I'm ready.</NextButton>
    </Column>
  </div>
);

export { Control };
