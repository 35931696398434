import React from 'react';

export const Paths: React.FC<{ paths: string[][] }> = ({ paths }) => (
  <div
    style={{
      marginTop: 10,
      color: 'rgba(255, 255, 255, 0.4)',
      fontSize: 10
    }}
  >
    <div>B.R.A.T. Detection Routes</div>
    <table>
      <tbody>
        {paths.map((path, i) => (
          <tr key={path.join('--')}>
            {paths.length > 1 && (
              <td
                valign="top"
                style={{
                  whiteSpace: 'nowrap',
                  paddingRight: 5
                }}
              >
                #{i + 1}
              </td>
            )}
            <td>
              {path.map((p, i) => (
                <React.Fragment key={i}>
                  {i > 0 && (
                    <span style={{ marginRight: 5, marginLeft: 5 }}>→</span>
                  )}
                  <code key={`${path.join('--')}`} style={{ fontSize: 10 }}>
                    {p}
                  </code>
                </React.Fragment>
              ))}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);
