import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '@apollo/space-kit/reset.css';

import App from './App';

// only used in Mission.tsx, but we instantiate here so we can
// benefit from the AC cache for that page.
const client = new ApolloClient({
  uri: 'https://operation-homeworld-universe.herokuapp.com/',
  name: 'Mission Logistics',
  version: 'request mission vector',
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById('root')
);
