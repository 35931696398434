import React, { useState } from 'react';
import { LoadingSpinner } from '@apollo/space-kit/Loaders';
import { colors } from '@apollo/space-kit/colors';
import { Tooltip } from '@apollo/space-kit/Tooltip';
import { IconInfoSolid } from '@apollo/space-kit/icons/IconInfoSolid';
import { useMutation, useQuery, gql } from '@apollo/client';
import { ALIENS } from '../../App';

const SUBMIT_PLANET = gql`
  mutation SubmitPlanet(
    $submitPlanetPlanetId: ID!
    $submitPlanetUsername: String!
  ) {
    submitPlanet(
      planetId: $submitPlanetPlanetId
      username: $submitPlanetUsername
    ) {
      habitable
      planetCommentary
      planetScore
    }
  }
`;

const GET_LEADERS = gql`
  query GetLeaderboard {
    leaders {
      submittedAt
      name
      score
      commentary
      planet {
        name
      }
    }
  }
`;

const Mission: React.FC = () => {
  const [planet, setPlanet] = useState('');
  const [username, setUsername] = useState('');
  const { data, loading } = useQuery(GET_LEADERS);
  const [
    submitPlanet,
    { data: submitData, loading: submitting, error: submitError },
  ] = useMutation(SUBMIT_PLANET, { refetchQueries: [{ query: GET_LEADERS }] });

  const leaders = data && data.leaders ? data.leaders.slice() : [];
  leaders.sort((a: any, b: any) => b.score - a.score);

  const colorKeys = Object.keys(colors).filter(
    (k) => k !== 'black' && k !== 'grey' && k !== 'whtie'
  );
  return (
    <div className="row">
      <div className="col-md-8 offset-md-2 col-12">
        <h1>Report Findings</h1>
      </div>
      <div className="col-lg-5 offset-lg-2 col-12">
        <p>
          Once you have found a suitable planet for the {ALIENS}, you should
          submit it to Mission Control for consideration. Mission Control
          expects that there are several planets out there in the universe that
          the {ALIENS} can habitate on.
        </p>
        <p>
          Once you've submitted your planet proposal, Mission Control will use
          the <code>B.R.A.T.</code> to investigate the planet's properties and
          report back to you whether your submission was viable and what your{' '}
          <code>planetScore</code> is.
        </p>
        <p>
          Your final planet score can range anywhere from <code>0</code> to{' '}
          <code>100</code> depending on how much the {ALIENS} like what you have
          offered them. Despite their pickiness though, the {ALIENS} are deeply
          grateful for any submission of a habitable planet discovery and will
          reward the submitter with a commemorative spot on their{' '}
          <strong>Leaderboard</strong>.
        </p>
      </div>
      <div id="leaderboard" className="col-lg-3 col-md-6 col-12">
        {loading && (
          <div>
            <LoadingSpinner size="xsmall" theme="dark" />
          </div>
        )}

        {data && data.leaders && leaders.length ? (
          <table style={{ marginLeft: 'auto' }}>
            <thead>
              <tr>
                <th style={{ paddingRight: 30 }}>Score</th>
                <th style={{ paddingRight: 30 }}>Username</th>
                {/* <th style={{ paddingRight: 30 }}>Planet</th> */}
              </tr>
            </thead>
            <tbody style={{ fontSize: 12 }}>
              {leaders.map(
                (
                  { name, score, planet, commentary, submittedAt }: any,
                  i: number
                ) => {
                  const j = i % colorKeys.length;
                  const c = colorKeys[j];
                  return (
                    <tr key={submittedAt}>
                      <td>
                        <code
                          style={{
                            // @ts-ignore
                            color: colors[c].base,
                          }}
                        >
                          {score}
                        </code>
                      </td>
                      <td>
                        <code
                          style={{
                            // @ts-ignore
                            color: colors[c].base,
                          }}
                        >
                          {name}
                        </code>
                        <Tooltip content={commentary}>
                          <IconInfoSolid
                            style={{
                              height: 12,
                              marginLeft: 8,
                            }}
                          />
                        </Tooltip>
                      </td>
                      {/* <td style={{ display: 'flex', alignItems: 'center' }}>
                        <code
                          style={{
                            // @ts-ignore
                            color: colors[c].base,
                          }}
                        >
                          {planet.name}
                        </code>
                      </td> */}
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        ) : (
          <code>
            No habitable planets have been submitted to the Bergals yet.
          </code>
        )}
      </div>
      <div className="col-lg-8 offset-lg-2 col-12" style={{ marginBottom: 40 }}>
        <form
          style={{ marginTop: 20 }}
          onSubmit={(e) => {
            e.preventDefault();
            if (!username) {
              throw new Error('sadness');
            }
            submitPlanet({
              variables: {
                submitPlanetPlanetId: planet,
                submitPlanetUsername: username,
              },
            });
          }}
        >
          <div
            style={{
              paddingTop: 10,
              paddingBottom: 10,
              paddingLeft: 20,
              paddingRight: 20,
              fontSize: 12,
              backgroundColor: colors.indigo.dark,
              fontFamily: 'Menlo',
            }}
          >
            {!submitData && (
              <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <table>
                  <tbody>
                    <tr>
                      <td style={{ marginRight: 20 }}>Planet:</td>
                      <td>
                        <input
                          autoFocus
                          type="text"
                          value={planet}
                          onChange={(e) => {
                            setPlanet(e.target.value);
                          }}
                          style={{ width: 200, marginRight: 10, height: 19 }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ marginRight: 20 }}>Username:</td>
                      <td>
                        <input
                          type="text"
                          value={username}
                          onChange={(e) => {
                            setUsername(e.target.value);
                          }}
                          style={{ width: 200, marginRight: 10, height: 19 }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <button
                  style={{
                    fontSize: 12,
                    backgroundColor: 'transparent',
                    border: 'none',
                    color: 'white',
                  }}
                  type="submit"
                >
                  Submit
                </button>
              </div>
            )}

            {submitting && (
              <div style={{ marginTop: 10 }}>
                <LoadingSpinner size="2xsmall" theme="dark" />
              </div>
            )}
            {submitError &&
              submitError.graphQLErrors &&
              submitError.graphQLErrors.map(({ message }: any) => (
                <div style={{ marginTop: 10 }} key="message">
                  <code>{message}</code>
                </div>
              ))}
            {submitData && (
              <div style={{ marginTop: 10 }}>
                <div style={{ marginBottom: 10 }}>
                  {submitData.submitPlanet.habitable ? (
                    <span>
                      <strong>Congratulations!!</strong> You found a habitable
                      planet for the Bergals.
                    </span>
                  ) : (
                    `This planet is not habitable by the Bergals.`
                  )}
                </div>
                <div style={{ marginBottom: 10 }}>
                  <strong>Planet score:</strong>{' '}
                  <code>{submitData.submitPlanet.planetScore}</code>
                </div>

                <div>
                  <code>{submitData.submitPlanet.planetCommentary}</code>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

/**
 * Credits to Stephen for generating reasonable sounding planet names for us from sci-fi
 * dataset, and then cleaning out all the names for bad words :) Also thank you Stephen
 * for the hilarious narrative of the Bergals.
 *
 * Credits to Jason, Josh, Sachin, Alisha, Faby, and many more for helping create the game
 * server and test playing.
 */

export { Mission };
