import React, { useState, useRef } from 'react';
import { colors } from '@apollo/space-kit/colors';
import { LoadingSpinner } from '@apollo/space-kit/Loaders';
import { gql } from '@apollo/client';
import { useInterval } from '../../helpers/useInterval';
import { useLocalStorage } from '../../helpers/useLocalStorage';
import { client } from './Beacon';

const SEND_BEACON = gql`
  query SendBeacon($url: String!) {
    sendBeacon(url: $url) {
      clue {
        text
        type
      }
      results {
        operation
        passed
        result
      }
    }
  }
`;

const fetch = (
  url: string,
  setLoading: any,
  setResult: any,
  clues: any[],
  logClue: any,
  setClueMessage: any
) => {
  client
    .query({
      fetchPolicy: 'no-cache',
      query: gql`
        ${SEND_BEACON}
      `,
      variables: { url },
    })
    .then(({ data }) => {
      setLoading(false);
      if (data && data.sendBeacon && data.sendBeacon.clue) {
        if (
          clues.filter((c) => c.text === data.sendBeacon.clue.text).length === 0
        ) {
          setClueMessage('Calculation complete. New intel processed.');
          clues.push(data.sendBeacon.clue);
          logClue(JSON.stringify(clues));
        } else {
          setClueMessage('Calculation complete. Old intel re-discovered.');
        }
      } else {
        setClueMessage('Calculation incomplete. No intel could be extracted.');
      }
      if (data && data.sendBeacon && data.sendBeacon.results) {
        setResult(data.sendBeacon.results);
      }
    })
    .catch((e) => {
      if (
        e.networkError &&
        e.networkError.result &&
        e.networkError.result.errors
      ) {
        setResult(e.networkError.result.errors);
      } else if (e.name && e.message) {
        setResult([
          {
            name: e.name,
            message: e.message,
          },
        ]);
      } else {
        console.error(e);
      }
      setLoading(false);
    });
};

interface Props {
  clues: any[];
  logClue: (clue: string) => void;
}

export const Receiver: React.FC<Props> = ({ clues, logClue }) => {
  const input = useRef(null);
  const [url, setUrl] = useLocalStorage('puzzlehunt:probeUrl', '');
  const [open, setOpen] = useState(url.length === 0);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<any[]>([]);
  const [clueMessage, setClueMessage] = useState('');
  const [count, setCount] = useState(4);
  const [savedReceiving, setReceiving] = useLocalStorage(
    'puzzlehunt:receiving',
    'false'
  );
  const receiving = JSON.parse(savedReceiving);

  useInterval(
    () => {
      if ((count + 1) % 5 === 0) {
        // anticipate next count so we trigger on round modulo
        setLoading(true);
        fetch(url, setLoading, setResult, clues, logClue, setClueMessage);
      }
      setCount((count + 1) % 5);
    },
    receiving ? 1000 : null
  );

  const showScorecard = result.length > 0 && result[0].hasOwnProperty('passed');

  return (
    <div style={{ marginBottom: 20, backgroundColor: colors.indigo.darkest }}>
      <div
        style={{
          paddingLeft: 20,
          fontSize: 12,
          lineHeight: 28 / 12,
          backgroundColor: colors.indigo.dark,
          fontFamily: 'Menlo',
        }}
      >
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <strong style={{ marginRight: 24, flex: 0 }}>Receiver:</strong>
            {open ? (
              <input
                autoFocus
                style={{ flex: 1, height: 20 }}
                ref={input}
                type="text"
                defaultValue={url}
                onKeyDown={(e) => {
                  if (e.keyCode === 27) {
                    setOpen(false);
                  } else if (e.keyCode === 13) {
                    // @ts-ignore
                    setUrl(input.current.value);
                    setOpen(false);
                    setReceiving('true');
                  }
                }}
              />
            ) : (
              <code style={{ flex: 1 }}>{url}</code>
            )}
            <span
              style={{
                flex: 0,
                color: colors.white,
                cursor: 'pointer',
                float: 'right',
                marginRight: 20,
                marginLeft: 20,
                textDecoration: 'underline',
              }}
              onClick={() => {
                if (open) {
                  // @ts-ignore
                  setUrl(input.current.value);
                  setOpen(false);
                  setReceiving('true');
                } else {
                  setOpen(true);
                }
              }}
            >
              {open ? 'Enter' : 'Change'}
            </span>
          </div>
          <div>
            <strong>Receiving:</strong>
            <span
              style={{
                marginLeft: 30,
                cursor: 'pointer',
                textDecoration: receiving ? 'underline' : 'none',
              }}
              onClick={() => {
                setReceiving('true');
              }}
            >
              On
            </span>
            <span
              style={{
                marginLeft: 30,
                marginRight: 30,
                cursor: 'pointer',
                textDecoration: receiving ? 'none' : 'underline',
              }}
              onClick={() => {
                setReceiving('false');
              }}
            >
              Off
            </span>
            {receiving && <span>{5 - count}</span>}
            {receiving && (
              <div style={{ marginLeft: 30, display: 'inline-block' }}>
                {loading && <LoadingSpinner size="2xsmall" theme="dark" />}
              </div>
            )}
          </div>
        </div>
      </div>
      {result.length > 0 &&
        (showScorecard ? (
          <>
            <table style={{ fontFamily: 'Menlo', fontSize: 12 }}>
              <tbody>
                {result.map(({ passed, operation, result }, i) => (
                  <tr key={i}>
                    <td style={{ padding: '0 20px' }}>{operation}</td>
                    <td
                      style={{
                        padding: '0 4px',
                        backgroundColor: passed
                          ? colors.green.base
                          : colors.red.base,
                      }}
                    >
                      {passed ? 'PASS' : 'FAIL'}
                    </td>
                    <td>
                      {result && (
                        <code style={{ marginLeft: 20 }}>{result}</code>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div
              style={{
                paddingLeft: 20,
                marginTop: 10,
                fontFamily: 'Menlo',
                fontSize: 12,
              }}
            >
              {clueMessage}
            </div>
          </>
        ) : (
          <pre
            style={{
              padding: 10,
              paddingLeft: 20,
              fontSize: 12,
              color: colors.white,
              margin: 0,
            }}
          >
            {JSON.stringify(result, null, 2)}
          </pre>
        ))}
    </div>
  );
};
