import React from 'react';
import { ALIENS, Column, NextButton } from '../../App';

const Briefing: React.FC = () => (
  <div className="row">
    <Column>
      <h1>Mission Briefing</h1>
      <p>
        The <strong>{ALIENS}</strong> need your help!
      </p>
      <p>
        In just a few thousand years, the {ALIENS}' <em>entire home galaxy</em>{' '}
        and beloved homeworld <code>Antala</code> will be destroyed by a chain
        reaction of supernovas. They need to{' '}
        <b>settle a new planet in a nearby galaxy</b> before all is lost. The{' '}
        {ALIENS} are a highly advanced alien species and are capable of
        intergalactic travel, but their enormous colony ship only has enough
        fuel for a single hyperspeed trip. Because of this,{' '}
        <strong>
          they need to know exactly which coordinates to punch in before they
          take off.
        </strong>
      </p>
      <p>
        <strong>Your mission:</strong> find a habitable planet for the {ALIENS}{' '}
        to settle, before it's too late.
      </p>
      <NextButton nextPage="control">I accept. Let's get started.</NextButton>
    </Column>
  </div>
);

export { Briefing };
