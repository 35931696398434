import React from 'react';
import { colors } from '@apollo/space-kit/colors';

interface Moon {
  id: string;
}

interface Props {
  moons: Moon[];
}

export const Moons: React.FC<Props> = ({ moons }) => (
  <div style={{ marginTop: 10 }}>
    <div
      style={{
        flex: 1,
        color: 'rgba(255, 255, 255, 0.4)',
        fontSize: 10
      }}
    >
      {moons.length === 0 && 'No'} Moons
    </div>
    {moons.map(({ id }) => (
      <code key={id} style={{ fontSize: 10, color: colors.yellow.base }}>
        {id}
      </code>
    ))}
  </div>
);
