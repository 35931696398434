import React, { useState } from 'react';
import { colors } from '@apollo/space-kit/colors';
import { ALIEN, ALIENS, Column, NextButton } from '../../App';
import { Planet } from './Planet';
import { ClimateType } from './Climate';
import { AtmosphereRatio } from './Atmosphere';
import { TemperatureType } from './Temperature';
import { Processor } from './Processor';
import { Filter, filterPlanets } from './Filter';
import { useLocalStorage } from '../../helpers/useLocalStorage';

// import data from './data.json';

interface Star {
  name?: string;
  type?: string;
  planets?: Planet[];
  neighbors?: Star[];
  slingshot?: boolean;
}

export interface Planet {
  star?: Star;
  name?: string;
  climate?: ClimateType;
  temperature?: TemperatureType;
  inhabited?: boolean;
  dragons?: boolean;
  blockbuster?: boolean;
  moons?: { id: string }[];
  atmosphere?: AtmosphereRatio;
  rings?: number;
}

export interface PlanetWithPaths {
  planet: Planet;
  paths: string[][];
}

const visit = (star: Star, path: string[] = []): PlanetWithPaths[] => {
  const habitable: PlanetWithPaths[] = [];

  if (star.planets)
    star.planets.forEach((planet) => {
      if (planet.name) {
        const { planets, ...starMinusPlanets } = star;
        habitable.push({
          planet: {
            star: starMinusPlanets,
            ...planet,
          },
          paths: [[...path, star.name || '[unidentified star]']],
        });
      }
    });

  if (star.neighbors)
    star.neighbors.forEach((s) => {
      const foundPlanets = visit(s, [
        ...path,
        star.name || '[unidentified star]',
      ]);
      foundPlanets.forEach(({ planet, paths }) => {
        const i = habitable.findIndex((q) => q.planet.name === planet.name);
        if (i > -1) {
          // seen planet before, so we found a new path
          habitable[i].paths.push(...paths);

          const snakes = habitable[i].paths.map((a) => a.join('--'));
          habitable[i].paths = habitable[i].paths
            .filter((arr, i) => snakes.indexOf(arr.join('--')) === i)
            .filter(
              (arr) => arr.filter((i, j) => arr.indexOf(i) !== j).length === 0
            );
        } else {
          // new planet
          habitable.push({ planet, paths });
        }
      });
    });

  return habitable;
};

export const Homeworld: React.FC = () => {
  const [starredP, setStarredP] = useLocalStorage('puzzlehunt:planets', '[]');
  const starredPlanets: Planet[] = JSON.parse(starredP);

  const [file, loadFile] = useState<File | null>(null);
  const [strFilter, setFilter] = useLocalStorage(
    'puzzlehunt:filter',
    JSON.stringify({
      temperature: { min: 0, max: 1000 },
      climate: [],
      atmosphere: {
        argonRatio: { value: 0.2, filterType: 'max' },
        carbonDioxideRatio: { value: 0.2, filterType: 'max' },
        nitrogenRatio: { value: 0.2, filterType: 'max' },
        oxygenRatio: { value: 0.2, filterType: 'max' },
        sulfuricAcidRatio: { value: 0.2, filterType: 'max' },
      },
    })
  );
  const filter = JSON.parse(strFilter);

  // @ts-ignore
  // const planets = visit(data.data.star);
  // const findPlanets = (planets: PlanetWithPaths[]) => {};
  const [planets, findPlanets] = useState<PlanetWithPaths[]>([]);

  return (
    <>
      <div className="row">
        <Column>
          <h1>Find a New Homeworld</h1>
          <p>
            Now that you're comfortable with the <code>B.R.A.T.</code>, let's{' '}
            <strong>find a new homeworld</strong> for the {ALIENS}. The{' '}
            <code>B.R.A.T.</code> can report back details about every observable
            property from the stars and planets it sees{' '}
            <strong>
              <i>if you ask</i>
            </strong>
            .
          </p>
          <h5 style={{ marginTop: 40 }}>
            {ALIEN} habitation requirements{' '}
            <span role="img" aria-label="sprout">
              🌱
            </span>{' '}
          </h5>
          <p>
            {ALIENS} can only live on planets whose properties support their
            life systems. Here’s everything that’s <strong>necessary</strong>{' '}
            for the {ALIENS} to be able to settle a planet:
          </p>
          <ul>
            <li>
              The planet must orbit either a <code>RED_DWARF</code> or{' '}
              <code>MAIN_SEQUENCE</code> star.
            </li>
            <li>
              The planet’s star must have no <em>neighboring</em> star that is a{' '}
              <code>BLACK_HOLE</code> or <code>SUPERGIANT</code>.
            </li>
            <li>
              The planet’s climate must be <code>TROPICAL</code>,{' '}
              <code>DAMP</code>, or <code>TEMPERATE</code>.
            </li>
            <li>
              The planet’s temperature must never go below <code>210</code> or
              exceed <code>350</code> kelvin.
            </li>
            <li>
              The <code>argonRatio</code> of the planet's atmosphere must be at
              least <code>0.6</code>, and the <code>sulfuricAcidRatio</code>{' '}
              cannot exceed <code>0.05</code>.
            </li>
          </ul>
          <h5 style={{ marginTop: 40 }}>
            Transfer data to the processor{' '}
            <span role="img" aria-label="floppy disk">
              💾
            </span>
          </h5>
          <p>
            The data from the <code>B.R.A.T.</code> is raw and can be hard to
            process on its own. To help, the {ALIENS} have built a{' '}
            <strong>planetary data processor</strong> for you to use. Download
            your raw data from the <code>B.R.A.T.</code>{' '}
            <i>(icon in the top right corner)</i> to a local file, and drop it
            on the processor to get started. The planetary data processor will
            also <strong>help you to filter planets</strong>!
          </p>
          <div
            style={{
              fontSize: 12,
              display: 'flex',
              width: file ? 'auto' : 'min-content',
              backgroundColor: colors.indigo.dark,
              fontFamily: 'Menlo',
            }}
          >
            <Processor
              onUpdate={(file) => {
                loadFile(file);
                if (file.type === 'application/json') {
                  const reader = new FileReader();
                  reader.onloadend = function (e) {
                    // @ts-ignore
                    const result = JSON.parse(this.result);
                    if (result.data && result.data.star) {
                      const planets = visit(result.data.star);
                      findPlanets(planets);
                    }
                  };
                  reader.readAsText(file);
                }
              }}
            />
            {file && (
              <div style={{ padding: 20, flex: 1 }}>
                <div>
                  <strong>Name:</strong> {file.name}
                </div>
                <div>
                  <strong>Type:</strong> {file.type}
                </div>
                <div style={{ marginTop: 10 }}>
                  <code>
                    {file.type === 'application/json'
                      ? planets.length > 0
                        ? `The processor found ${planets.length} planets in this data, loaded below.`
                        : 'The processor did not find any planets in this data.'
                      : 'The processor could not read this type of file... please upload a JSON file.'}
                  </code>
                </div>
              </div>
            )}
          </div>
          {planets.length > 0 && (
            <>
              <h5 style={{ marginTop: 40 }}>
                Tips for using the processor{' '}
                <span role="img" aria-label="present">
                  🎁
                </span>
              </h5>
              <ul>
                <li>
                  The processor can only work with{' '}
                  <strong>one dataset at a time</strong>, and will reload every
                  time you drop a file on it.
                </li>
                <li>
                  You can{' '}
                  <strong>save planets you like to your workspace</strong> with
                  the{' '}
                  <span role="img" aria-label="star">
                    ⭐
                  </span>
                  icon on each planet info card.
                </li>
                <li>
                  The <strong>more data you load</strong> into the processor,
                  the <strong>more details you will see</strong> on the planet
                  cards.
                </li>
                <li>
                  You can use the processor's <strong>filter settings</strong>{' '}
                  to narrow down the planet list you're looking at.
                </li>
              </ul>
              <h5 style={{ marginTop: 40 }}>
                Planetary data processor{' '}
                <span role="img" aria-label="telescope">
                  🔭
                </span>
              </h5>
              <Filter planets={planets} filter={filter} setFilter={setFilter} />
            </>
          )}
        </Column>
      </div>

      {starredPlanets.length > 0 && (
        <div className="row" style={{ marginBottom: 20 }}>
          <div className="col-md-8 offset-md-2 col-12">
            <h5 style={{ marginTop: 20 }}>
              Saved planets{' '}
              <span role="img" aria-label="star">
                ⭐
              </span>
            </h5>
          </div>
          {starredPlanets.map((planet, i) => {
            return (
              <Planet
                key={i}
                // paths={paths}
                planet={planet}
                canClose={true}
                starPlanet={() => {
                  starredPlanets.splice(i, 1);
                  setStarredP(JSON.stringify(starredPlanets));
                }}
              />
            );
          })}
        </div>
      )}

      <div className="row">
        {planets.length > 0 && (
          <div className="col-md-8 offset-md-2 col-12">
            <h5 style={{ marginTop: 20 }}>
              Processed planets{' '}
              <span role="img" aria-label="water tap">
                🚰
              </span>
            </h5>
          </div>
        )}
        {filterPlanets(filter, planets).map(({ planet, paths }) => {
          const isStarred = starredPlanets
            .map((p) => p.name)
            .includes(planet.name);
          return (
            <Planet
              key={planet.name}
              paths={paths}
              planet={planet}
              isStarred={isStarred}
              starPlanet={(p: Planet) => {
                if (isStarred) {
                  const i = starredPlanets.findIndex(
                    (p) => p.name === planet.name
                  );
                  starredPlanets.splice(i, 1);
                  setStarredP(JSON.stringify(starredPlanets));
                } else {
                  const newPlanets = [...starredPlanets, p];
                  setStarredP(JSON.stringify(newPlanets));
                }
              }}
            />
          );
        })}
      </div>

      <div className="row">
        <Column style={{ marginTop: 20 }}>
          <NextButton disabled={planets.length === 0} nextPage="sensitive">
            I found a planet for the {ALIENS}.
          </NextButton>
        </Column>
      </div>
    </>
  );
};
