import React, { useContext } from 'react';
import classnames from 'classnames';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  RouteComponentProps,
  Link,
  Redirect,
} from 'react-router-dom';
import { Button } from '@apollo/space-kit/Button';
import { colors } from '@apollo/space-kit/colors';
import { useLocalStorage } from './helpers/useLocalStorage';
import { Info } from './app/info/Info';
import { Briefing } from './app/briefing/Briefing';
import { Control } from './app/control/Control';
import { Beacon } from './app/beacon/Beacon';
import { Galaxy } from './app/galaxy/Galaxy';
import { Homeworld } from './app/homeworld/Homeworld';
import { Sensitive } from './app/sensitive/Sensitive';
import { Mission } from './app/mission/Mission';
import './App.css';

const ALIEN = 'Bergal';
const ALIENS = 'Bergals';

const Column: React.FC<{ style?: object }> = ({ children, style }) => (
  <div
    className="col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-12"
    style={style}
  >
    {children}
  </div>
);

const NavLink: React.FC<{
  disabled: boolean;
  matchPath: string;
  page: string;
  index: number;
}> = ({ disabled, matchPath, page, index }) =>
  disabled ? (
    <div className="tab disabled">{index}</div>
  ) : (
    <Link
      to={`/${page}`}
      className={classnames('tab', matchPath === page && 'active')}
    >
      {index}
    </Link>
  );

const NavContext = React.createContext({
  goToNextPage: (s: string) => {},
  navigableLinks: [''],
});

interface HeaderProps {
  path: string;
}

const Header: React.FC<RouteComponentProps<HeaderProps>> = ({ match }) => {
  const { navigableLinks } = useContext(NavContext);
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 500,
        paddingLeft: 40,
        paddingRight: 40,
        width: '100%',
        marginTop: 40,
        marginBottom: 40,
      }}
    >
      {[
        'info',
        'briefing',
        'control',
        'beacon',
        'galaxy',
        'homeworld',
        'sensitive',
        'mission',
      ].map((page, index) => (
        <NavLink
          disabled={!navigableLinks.includes(page)}
          key={page}
          matchPath={match.params.path}
          page={page}
          index={index}
        />
      ))}
    </div>
  );
};

export const NextButton: React.FC<{
  disabled?: boolean;
  nextPage: string;
}> = ({ disabled = false, nextPage, children }) => {
  const { goToNextPage } = useContext(NavContext);
  return (
    <div>
      <Button
        as={<Link to={`/${nextPage}`} />}
        disabled={disabled}
        onClick={() => {
          goToNextPage(nextPage);
        }}
        color={colors.blue.base}
        theme="dark"
        style={{ marginTop: 20 }}
      >
        {children || 'Continue'}
      </Button>
    </div>
  );
};

const App: React.FC = () => {
  const [navStateString, setNavState] = useLocalStorage(
    'puzzlehunt:nav',
    JSON.stringify(['info'])
  );
  const navState = JSON.parse(navStateString);

  const goToNextPage = (next: string) => {
    navState.push(next);
    setNavState(JSON.stringify(Array.from(new Set(navState))));
  };

  return (
    <Router>
      <NavContext.Provider value={{ goToNextPage, navigableLinks: navState }}>
        <div
          style={{ backgroundColor: colors.black.darker, color: colors.white }}
          className="App"
        >
          <div className="container" style={{ marginBottom: 40 }}>
            <header className="row">
              <Route path="/:path" component={Header} />
            </header>

            <Switch>
              <Route path="/info" component={Info} />
              <Route path="/briefing" component={Briefing} />
              <Route path="/control" component={Control} />
              <Route path="/beacon" component={Beacon} />
              <Route path="/galaxy" component={Galaxy} />
              <Route path="/homeworld" component={Homeworld} />
              <Route path="/sensitive" component={Sensitive} />
              <Route path="/mission" component={Mission} />
              <Redirect to="/info" />
            </Switch>
          </div>
        </div>
      </NavContext.Provider>
    </Router>
  );
};

export default App;
export { ALIEN, ALIENS, Column };
