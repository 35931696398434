import React from 'react';
import { ApolloClient, InMemoryCache, useQuery, gql } from '@apollo/client';
import { colors } from '@apollo/space-kit/colors';
import { useLocalStorage } from '../../helpers/useLocalStorage';
import { ALIEN, ALIENS, Column, NextButton } from '../../App';
import { Receiver } from './Receiver';

const GET_COUNT = gql`
  query GetClueCount {
    clueCount
  }
`;

export const client = new ApolloClient({
  uri: 'https://operation-homeworld-beacon.herokuapp.com/',
  name: 'Beacon',
  version: 'transmitter',
  cache: new InMemoryCache(),
});

export const Beacon: React.FC = () => {
  const { data } = useQuery(GET_COUNT, { client });
  const [foundClues, logClue] = useLocalStorage('puzzlehunt:clues', '[]');
  const clues = JSON.parse(foundClues);

  return (
    <div className="row">
      <Column>
        <h1>Make Contact with the Beacon</h1>
        <p>
          The beacon transmits data to the {ALIEN} homeworld via—what
          else?—GraphQL. But right now, the {ALIENS} don’t have a system in
          place to <em>receive</em> that data. That’s where you come in!
        </p>
        <h5 style={{ marginTop: 40 }}>
          Build a receiver{' '}
          <span role="img" aria-label="satellite dish">
            📡
          </span>
        </h5>
        <p>
          A receiver from one of the {ALIENS}' previous deep-space missions has
          been{' '}
          <a
            href="https://glitch.com/edit/#!/ossified-airy-pike"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'underline', color: colors.blue.light }}
          >
            saved for historic preservation on Glitch
          </a>
          . They have made all their technology backwards-compatible, so the old
          receiver's schematics will work for yours with a few calculation
          modifications for this beacon's specifics.
        </p>
        <p>
          To create a carbon copy of the old receiver as your starting point,
          click the{' '}
          <strong>
            "Remix to Edit{' '}
            <span role="img" aria-label="microphone">
              🎤
            </span>
            "
          </strong>{' '}
          button in Glitch. Once your new receiver is spun up and ready,
          register it with the beacon to start receiving pings.
        </p>
        <ul style={{ marginBottom: 20 }}>
          <li>
            Click the <strong>"Share"</strong> button in Glitch
          </li>
          <li>
            Select the <strong>"Live App"</strong> option
          </li>
          <li>
            <strong>Copy</strong> the link to your receiver, and{' '}
            <strong>paste</strong> it in the box below:
          </li>
        </ul>
        <Receiver clues={clues} logClue={logClue} />

        <h5 style={{ marginTop: 40 }}>
          Respond to beacon requests{' '}
          <span role="img" aria-label="divide sign">
            ➗
          </span>
        </h5>
        <p>
          Once you have a receiver, you need to figure out how to respond to the
          beacon's pings.
        </p>
        <p>
          Each of the beacon’s pings is a{' '}
          <strong>request for processing from the homeworld</strong>. The beacon
          needs help navigating the vast void of space, and only the homeworld
          has the processing power necessary to do the job. Your receiver needs
          to respond to each of these incoming requests with a response that
          matches the query's type. It’s up to you to create a resolver in the
          receiver that calculates the correct result for each query (
          <strong>what the query asks for is a pretty big hint...</strong>)
        </p>

        <h5 style={{ marginTop: 40 }}>
          Analyze your intel{' '}
          <span role="img" aria-label="magnifying glass">
            🔎
          </span>
        </h5>
        {clues.length === 0 ? (
          <p>
            As you start discovering intel,{' '}
            <strong>it will become available right here</strong>. You need to
            gather at least a few pieces of intel to proceed with the mission.
            You can keep track of your intel by making a copy of{' '}
            <a
                href="https://docs.google.com/spreadsheets/d/1Smo0n_CQl1iFWRijs33ODoMm8U2Azv7IzEfVW4I34sk/edit?usp=sharing"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'underline', color: colors.blue.light }}
            >
              this google sheet
            </a>{' '}
            or in the text editor of your choice.
          </p>
        ) : (
          <>
            {data ? (
              clues.length === data.clueCount ? (
                <p>
                  You have uncovered all {clues.length} of {data.clueCount}{' '}
                  pieces of intel that the intel the beacon can provide.
                </p>
              ) : (
                <p>
                  Found {clues.length} of {data.clueCount} pieces of intel.
                </p>
              )
            ) : (
              <p>Found {clues.length} piece of intel.</p>
            )}
            <p>
              Each <code>clue</code> is a human-solvable (or Google-solvable)
              riddle that might have lost a little bit of data during its
              intergalactic trip. The solution to every brainteaser is a single
              word, and capitalization doesn't matter.
            </p>
          </>
        )}
      </Column>
      {clues.map(({ text, type }: any, i: number) => (
        <div className="col-6" key={text}>
          <div
            style={{
              backgroundColor: colors.black.light,
              padding: '10px 20px',
              marginBottom: 30,
              borderRadius: 3,
            }}
          >
            <div
              style={{
                fontSize: 10,
                color: colors.silver.darker,
                marginBottom: 5,
              }}
            >
              <span>
                <b>Type:</b> {type}
              </span>
              <span style={{ float: 'right' }}>Clue #{i + 1}</span>
            </div>
            <code>{text}</code>
          </div>
        </div>
      ))}
      <Column style={{ marginBottom: 40 }}>
        <NextButton disabled={clues.length === 0} nextPage="galaxy">
          Found some intel, let's go.
        </NextButton>
      </Column>
    </div>
  );
};
