import React from 'react';
import { colors } from '@apollo/space-kit/colors';

export interface AtmosphereRatio {
  oxygenRatio?: number;
  nitrogenRatio?: number;
  argonRatio?: number;
  sulfuricAcidRatio?: number;
  carbonDioxideRatio?: number;
}
interface AtmosphereProps {
  atmosphere: AtmosphereRatio;
}
export const Atmosphere: React.FC<AtmosphereProps> = ({
  atmosphere: {
    oxygenRatio,
    nitrogenRatio,
    argonRatio,
    sulfuricAcidRatio,
    carbonDioxideRatio,
  },
}) => {
  const ratios = [];
  if (oxygenRatio) ratios.push(`Oxygen: ${(oxygenRatio * 100).toFixed(1)}%`);
  if (nitrogenRatio)
    ratios.push(`Nitrogen: ${(nitrogenRatio * 100).toFixed(1)}%`);
  if (argonRatio) ratios.push(`Argon: ${(argonRatio * 100).toFixed(1)}%`);
  if (sulfuricAcidRatio)
    ratios.push(`Sulfuric acid: ${(sulfuricAcidRatio * 100).toFixed(1)}%`);
  if (carbonDioxideRatio)
    ratios.push(`Carbon dioxide: ${(carbonDioxideRatio * 100).toFixed(1)}%`);

  return (
    <div
      title={ratios.join('\n')}
      style={{
        marginTop: 10,
      }}
    >
      <div
        style={{
          color: 'rgba(255, 255, 255, 0.4)',
          fontSize: 10,
          marginBottom: 2,
        }}
      >
        Atmosphere
      </div>
      <div
        style={{
          height: 6,
          width: '100%',
          backgroundColor: 'rgba(255, 255, 255, 0.05)',
          display: 'flex',
        }}
      >
        {oxygenRatio && (
          <div
            style={{
              width: `${oxygenRatio * 100}%`,
              backgroundColor: colors.blue.base,
            }}
          />
        )}
        {nitrogenRatio && (
          <div
            style={{
              width: `${nitrogenRatio * 100}%`,
              backgroundColor: colors.indigo.dark,
            }}
          />
        )}
        {argonRatio && (
          <div
            style={{
              width: `${argonRatio * 100}%`,
              backgroundColor: colors.pink.base,
            }}
          />
        )}
        {sulfuricAcidRatio && (
          <div
            style={{
              width: `${sulfuricAcidRatio * 100}%`,
              backgroundColor: colors.yellow.base,
            }}
          />
        )}
        {carbonDioxideRatio && (
          <div
            style={{
              width: `${carbonDioxideRatio * 100}%`,
              backgroundColor: colors.orange.base,
            }}
          />
        )}
      </div>
    </div>
  );
};
