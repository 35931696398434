import React from 'react';
import { ALIEN, Column, NextButton } from '../../App';
import { colors } from '@apollo/space-kit/colors';

const Galaxy: React.FC = () => (
  <div className="row">
    <Column>
      <h1>Explore the Galaxy</h1>
      <p>
        Remember how the {ALIEN} beacon has the unique ability to instantly
        travel to <strong>any star it knows the name of</strong>? Use the{' '}
        <code>B.R.A.T.</code> to{' '}
        <a
          href="https://studio.apollographql.com/graph/Beacon-Remote-Access-Terminal/explorer?document=query%20NicePlanetForGoodLivingPls%28%24starName%3A%20ID%21%29%20%7B%0A%20%20star%28name%3A%20%24starName%29%20%7B%0A%20%20%20%20name%0A%20%20%20%20planets%20%7B%0A%20%20%20%20%20%20name%0A%20%20%20%20%7D%0A%20%20%20%20neighbors%20%7B%0A%20%20%20%20%20%20name%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D&schemaTag=production&variables=%7B%0A%20%20%22starName%22%3A%20%22Antala%22%0A%7D"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'underline', color: colors.blue.light }}
        >
          tell the beacon to insta-zap
        </a>{' '}
        to one of the stars whose <code>STAR_NAME</code> you decoded from the{' '}
        <strong>intel</strong>.
      </p>
      <h5 style={{ marginTop: 40 }}>
        Get familiar with the <code>B.R.A.T.</code> 🧭
      </h5>
      <p>
        The beacon has advanced instrumentation onboard and can see every detail
        imaginable of stars and their planets when close by. Unfortunately, like
        most things in the physical world, it can only see so far...
      </p>
      <p>
        For any <code>Star</code> the beacon zaps to, it can see the star's{' '}
        <code>planets</code>, its <code>neighbors</code>, its{' '}
        <code>neighbor</code>'s <code>planets</code>, its <code>neighbor</code>
        's <code>neighbors</code>, etc. <i>to a certain extent</i>. Eventually,
        data becomes out of the beacon's range and it needs to zap to a{' '}
        <code>Star</code> on the edge of its range to see further into the
        galaxy.
      </p>
      <p>
        The beacon can also see details about each <code>Star</code> and{' '}
        <code>Planet</code> that impact whether {ALIEN} life can sustain there,
        like star <code>type</code> and planet <code>temperature</code>.
      </p>
      <p>
        Take some time to get familiar with the <code>B.R.A.T.</code> and see
        what you can find.
      </p>
      <h5 style={{ marginTop: 40 }}>
        Tips for using the{' '}
        <code>
          B.R.A.T.{' '}
          <span role="img" aria-label="present">
            🎁
          </span>
        </code>
      </h5>
      <ul>
        <li>
          You can zap to a star by changing values in the{' '}
          <strong>variables</strong> area.
        </li>
        <li>
          You can see which fields are available to be queried in the{' '}
          <strong>documentation</strong> panel.
        </li>
        <li>
          You can hold the <strong>command key</strong> down and click any field
          in your operation to open that field in the documentation panel.
        </li>
        <li>
          You can add fields to your operation with the{' '}
          <strong>plus (+) buttons</strong> in the documentation panel.
        </li>
        <li>
          Every time you change your operation or variables, you can click the{' '}
          <strong>run button</strong> to fetch fresh data.
        </li>
        <li>
          You can turn on <strong>table mode</strong> in the Explorer settings
          panel for easier response data browsing.
        </li>
        <li>
          You can <strong>download your data</strong> from the response panel
          with the icon in the top right corner.
        </li>
        <li>
          If you want a quick query for <i>"everything"</i>,{' '}
          <a
            href="https://studio.apollographql.com/graph/Beacon-Remote-Access-Terminal/explorer?document=fragment%20PlanetInfo%20on%20Planet%20%7B%0A%20%20name%0A%20%20climate%0A%20%20atmosphere%20%7B%0A%20%20%20%20argonRatio%0A%20%20%20%20carbonDioxideRatio%0A%20%20%20%20nitrogenRatio%0A%20%20%20%20oxygenRatio%0A%20%20%20%20sulfuricAcidRatio%0A%20%20%7D%0A%20%20temperature%20%7B%0A%20%20%20%20max%0A%20%20%20%20min%0A%20%20%7D%0A%7D%0A%0Afragment%20StarInfo%20on%20Star%20%7B%0A%20%20name%0A%20%20type%0A%20%20planets%20%7B%0A%20%20%20%20...PlanetInfo%0A%20%20%7D%0A%7D%0A%0Aquery%20AllTheGalacticThings%28%24starName%3A%20ID%21%29%20%7B%0A%20%20star%28name%3A%20%24starName%29%20%7B%0A%20%20%20%20...StarInfo%0A%20%20%20%20neighbors%20%7B%0A%20%20%20%20%20%20...StarInfo%0A%20%20%20%20%20%20neighbors%20%7B%0A%20%20%20%20%20%20%20%20...StarInfo%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D&schemaTag=production&variables=%7B%0A%20%20%22starName%22%3A%20%22Antala%22%0A%7D"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'underline', color: colors.blue.light }}
          >
            try this
          </a>{' '}
          <span role="img" aria-label="winky face">
            😉
          </span>
          .
        </li>
      </ul>
      <NextButton nextPage="homeworld">
        I know how to use the B.R.A.T.
      </NextButton>
    </Column>
  </div>
);

export { Galaxy };
