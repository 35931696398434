import React, { useState } from 'react';
import { colors } from '@apollo/space-kit/colors';

export const Processor: React.FC<{ onUpdate?: (file: File) => void }> = ({
  onUpdate,
}) => {
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  return (
    <div
      style={{
        width: 160,
        padding: 20,
        border: '1px dashed white',
        textAlign: 'center',
        flex: 'none',
        backgroundColor: isDraggedOver ? colors.indigo.light : 'transparent',
      }}
      onDragOver={(e) => {
        e.preventDefault();
        setIsDraggedOver(true);
      }}
      onDragEnter={(e) => {
        e.preventDefault();
        setIsDraggedOver(true);
      }}
      onDragEnd={(e) => {
        e.preventDefault();
        setIsDraggedOver(false);
      }}
      onDragLeave={(e) => {
        e.preventDefault();
        setIsDraggedOver(false);
      }}
      onDrop={(event) => {
        event.preventDefault();
        setIsDraggedOver(false);

        if (event.dataTransfer.files) {
          // pass file to the prop function
          if (onUpdate) onUpdate(event.dataTransfer.files[0]);
        }
      }}
    >
      <div style={{ fontSize: 24 }}>
        {isDraggedOver ? (
          <span role="img" aria-label="mailbox with mail">
            📬
          </span>
        ) : (
          <span role="img" aria-label="empty mailbox">
            📭
          </span>
        )}
      </div>
      <div>Planetary Data Processor</div>
    </div>
  );
};
