import React from 'react';
import { colors } from '@apollo/space-kit/colors';

export interface TemperatureType {
  min?: number;
  max?: number;
}

interface TemperatureProps {
  temperature: TemperatureType;
}

export const Temperature: React.FC<TemperatureProps> = ({
  temperature: { min, max }
}) => {
  return (
    <div style={{ marginTop: 10 }}>
      <div
        style={{
          color: 'rgba(255, 255, 255, 0.4)',
          fontSize: 10,
          marginBottom: 2
        }}
      >
        Kelvins{' '}
        {min && max && (
          <span style={{ float: 'right' }}>
            {min} - {max}
          </span>
        )}
      </div>
      <div
        style={{
          height: 1,
          width: '100%',
          backgroundColor: 'rgba(255, 255, 255, 0.4)',
          position: 'relative'
        }}
      >
        {min && (
          <div
            title={`Min: ${min}K`}
            style={{
              left: `${(min / 1000) * 100}%`,
              position: 'absolute',
              top: -4,
              height: 8,
              width: 8,
              borderRadius: 8,
              backgroundColor: colors.grey.light
            }}
          />
        )}
        {max && (
          <div
            title={`Max: ${max}K`}
            style={{
              left: `${(max / 1000) * 100}%`,
              position: 'absolute',
              top: -4,
              height: 8,
              width: 8,
              borderRadius: 8,
              backgroundColor: colors.grey.light
            }}
          />
        )}
      </div>
    </div>
  );
};
